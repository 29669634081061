import { Button, Card, Checkbox, Group, LoadingOverlay, Select, Stack, Text, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import { SectionBox } from "components/v2/SectionBox";
import React, { useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import BackendServiceV2 from "services/v2/BackendService";
import { Check, X } from "tabler-icons-react";

export function TeleportLayerSalesMap({layer}) {

    const queryClient = useQueryClient();

    const form = useForm({
        initialValues: {
            provider: '',
            identifier: '',
            autoSync: ''
        }
    });

    const {data: salesMap, isLoading: isLoadingData} = useQuery(['teleport-layer-sales-map', layer.id], () => {
        return BackendServiceV2.get(`/layer/${layer.id}/salesmap/minimal`);
    });

    const {data: integrationData, isLoading: isLoadingIntegrationData} = useQuery(['teleport-layer-sales-map-integration-data', layer.id], () => {
        return BackendServiceV2.get(`/layer/${layer.id}/salesmap/integration`);
    });
        
    useEffect(() => {
        form.setFieldValue('provider', salesMap?.provider);
        form.setFieldValue('identifier', salesMap?.providerIdentifier);
        form.setFieldValue('autoSync', salesMap?.autoSync);
    // eslint-disable-next-line
    }, [salesMap]);

    const {mutate: createSalesMap} = useMutation(() => {
        return BackendServiceV2.post(`/layer/${layer.id}/salesmap`)
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries('teleport-layer-sales-map');
            queryClient.invalidateQueries('teleport-layer-sales-map-integration-data');
            
            showNotification({
                title: 'Espelho de vendas criado com sucesso',
                message: '',
                icon: <Check />,
                color: 'green',
                autoClose: false
            });
        },
        onError: (error) => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                icon: <X />,
                color: 'red',
                autoClose: false
            });
        }
    });

    const {mutate: updateSalesMap, isLoading: isLoadingUpdate} = useMutation((data) => {
        return BackendServiceV2.put(`/layer/${layer.id}/salesmap`, data);
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries('teleport-layer-sales-map');
            queryClient.invalidateQueries('teleport-layer-sales-map-integration-data');

            showNotification({
                title: 'Espelho de vendas atualizado com sucesso',
                message: '',
                icon: <Check />,
                color: 'green',
                autoClose: 5000,
            });
        },
        onError: (error) => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                icon: <X />,
                color: 'red',
                autoClose: 5000,
            });
        }
    });

    return (
        <SectionBox 
            title="Espelho de Vendas"
        >
            <LoadingOverlay visible={isLoadingData || isLoadingUpdate || isLoadingIntegrationData} />

            {salesMap ? 
                <Stack>
                    <form onSubmit={form.onSubmit(values => updateSalesMap(values))}>
                        <Stack>
                            <Group noWrap grow>
                                <Select
                                    required
                                    label="Provedor"
                                    placeholder=""
                                    data={[
                                        {label: 'Órulo', value: 'ORULO'},
                                        {label: 'Facilita', value: 'FACILITA'},
                                        {label: 'iTeleport', value: 'ITELEPORT'},
                                    ]}
                                    {...form.getInputProps('provider')}
                                />

                                <TextInput
                                    required
                                    label={form.values.provider === 'ITELEPORT' ? 'Planilha' : 'ID'}
                                    {...form.getInputProps('identifier')}
                                />
                            </Group>

                            <Checkbox
                                label="Sincronizar diariamente"
                                description="Sincroniza exibição com espelho diariamente"
                                name="autoSync"
                                size="md"
                                checked={form.getInputProps('autoSync').value}
                                {...form.getInputProps('autoSync')}
                            />

                            <Button type="submit">Salvar</Button>
                        </Stack>
                    </form>
                    {integrationData &&
                        <Group style={{display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(120px, 1fr))', textAlign: 'center'}}>
                            {integrationData?.units?.map(unit => (
                                <Card withBorder p={8} style={{backgroundColor: unit?.availability === 'AVAILABLE' ? 'lightgreen' : 'lightgray'}}><Text size="xs">{unit.id}</Text></Card>
                            ))}
                        </Group>
                    }
                </Stack>
            :
                <Button onClick={createSalesMap}>Criar espelho de vendas</Button>
            }
        </SectionBox>
    )
}