import {  Button, Group, Table, Tabs, Text } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import {  FixedHeightContentWrapper } from "components/v2/Dashboard/Layout";
import React from "react";
import { useMutation, useQueryClient } from "react-query";
import BackendServiceV2 from "services/v2/BackendService";
import { Check, Refresh, X } from "tabler-icons-react";

export function TeleportSalesMapEditor({salesMaps}) {
    
    const queryClient = useQueryClient();

    const {mutate: syncWithIntegration} = useMutation((idLayer) => {
        return BackendServiceV2.post(`/layer/${idLayer}/salesmap/sync`);
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries('teleport-sales-map');
            queryClient.invalidateQueries('teleport-layer-sales-map');
            queryClient.invalidateQueries('teleport-layer-sales-map-integration-data');

            showNotification({
                title: 'Unidades atualizadas com sucesso',
                message: '',
                icon: <Check />,
                color: 'green',
                autoClose: 5000,
            });
        },
        onError: (error) => {
            showNotification({
                title: 'Um erro ocorreu',
                message: error?.response?.data?.message,
                icon: <X />,
                color: 'red',
                autoClose: 5000,
            });
        }
    });
    
    return (
        <FixedHeightContentWrapper>
            <Tabs 
                keepMounted={false}
                variant="outline"
                styles={(theme) => ({
                    root: {
                        flex: 1,
                        backgroundColor: '#FFF'
                    },
                    panel: {
                        height: '100%',
                        paddingTop: '0 !important'
                    }
                })}
                defaultValue={salesMaps[0]?.id}
            >
                <Tabs.List>
                    {salesMaps?.map(salesmap => (
                        <Tabs.Tab value={salesmap.id}>{salesmap.id}</Tabs.Tab>
                    ))}
                </Tabs.List>

                {salesMaps?.map(salesmap => (<>
                    <Tabs.Panel value={salesmap.id} pt="xs">
                        {salesmap.provider === 'ITELEPORT' ?
                            <Group grow noWrap position="right" p={15}>
                                <Button style={{flex: 0}} onClick={() => syncWithIntegration(salesmap.idLayer)} color="#ff5e00" leftIcon={<Refresh />}>Sincronizar com planilha</Button>
                            </Group>
                        :
                            <Group grow noWrap position="right" p={15}>
                                <Button style={{flex: 0}} onClick={() => syncWithIntegration(salesmap.idLayer)} color="#ff5e00" leftIcon={<Refresh />}>Sincronizar com Integração</Button>
                            </Group>
                        }
                        
                        <Table withColumnBorders style={{tableLayout: 'fixed'}}>
                            <thead>
                                <tr style={{backgroundColor: '#00000a', color: '#FFFFFF !important'}}>
                                    <th style={{color: '#FFF'}}>Torre</th>
                                    <th style={{color: '#FFF'}}>Unidade</th>
                                    <th style={{color: '#FFF'}}>Área</th>
                                    <th style={{color: '#FFF'}}>Preço</th>
                                    <th style={{color: '#FFF'}}>Cotas</th>
                                    <th style={{color: '#FFF'}}>Quartos</th>
                                    <th style={{color: '#FFF'}}>Banheiros</th>
                                    <th style={{color: '#FFF'}}>Vagas</th>
                                    <th style={{color: '#FFF'}}>Observações</th>
                                    <th style={{color: '#FFF'}}>Disponibilidade</th>
                                </tr>
                            </thead>

                            <tbody>
                                {salesmap.salesboxes?.map(salesbox => (<>
                                    <tr key={salesbox.id}>
                                        <td>{salesbox.tower}</td>
                                        <td>{salesbox.number}</td>
                                        <td>{salesbox.area}</td>
                                        <td>{salesbox.price}</td>
                                        <td>{salesbox.quota}</td>
                                        <td>{salesbox.bedrooms}</td>
                                        <td>{salesbox.bathrooms}</td>
                                        <td>{salesbox.garage}</td>
                                        <td><Text lineClamp={1}>{salesbox.observations}</Text></td>
                                        <td 
                                            style={{
                                                backgroundColor: salesbox.availability === 'AVAILABLE' ? 'lightgreen' : (salesbox.availability === 'UNAVAILABLE' ? 'lightgray' : 'lightyellow')
                                            }}
                                        >
                                            {salesbox.availability}
                                        </td>
                                    </tr>

                                    {false && <tr key={salesbox.id + '-integration'} style={{backgroundColor: '#f2f2f2'}}>
                                        <td colSpan={2}><Text inline color="dimmed" size="xs" weight={600} align="center">INTEGRAÇÂO</Text></td>
                                        <td><Text inline color="dimmed" size="xs">{salesbox.integrationData?.area}</Text></td>
                                        <td><Text inline color="dimmed" size="xs">{salesbox.integrationData?.price}</Text></td>
                                        <td><Text inline color="dimmed" size="xs">{salesbox.integrationData?.bedrooms}</Text></td>
                                        <td><Text inline color="dimmed" size="xs">{salesbox.integrationData?.bathrooms}</Text></td>
                                        <td><Text inline color="dimmed" size="xs">{salesbox.integrationData?.garage}</Text></td>
                                        <td 
                                            style={{
                                                backgroundColor: salesbox.availability === 'AVAILABLE' ? 'lightgreen' : (salesbox.availability === 'UNAVAILABLE' ? 'lightgray' : 'lightyellow')
                                            }}
                                        >
                                            <Text inline color="dimmed" size="xs">{salesbox.integrationData?.availability}</Text>
                                        </td>
                                    </tr>}
                                </>))}
                            </tbody>
                        </Table>
                    </Tabs.Panel>
                </>))}
                
            </Tabs>
        </FixedHeightContentWrapper>
    )
}